/** @jsx jsx */
import React, { useState } from "react";
import { jsx, Styled, Image } from "theme-ui";
import wechat from "../assets/wechat.jpg";

type ImgLinkProps = {
  title: string;
  href: String;
};

const ImgLink = ({ title, href }: ImgLinkProps) => {
  const [visible, setVisible] = useState(false);

  const showImg = (e) => {
    e.preventDefault();
    setVisible(true);
  };

  return (
    <React.Fragment>
      {visible && (
        <Styled.div
          sx={{
            width: `100vw`,
            height: `100vh`,
            background: `rgba(0,0,0,.5)`,
            position: `fixed`,
            top: 0,
            left: 0,
            display: `flex`,
            justifyContent: `center`,
            alignItems: `center`,
          }}
          onClick={() => setVisible(false)}
        >
          <Image
            src={wechat}
            alt="wechat"
            sx={{
              // width: `60%`,
              height: `50%`,
            }}
          />
        </Styled.div>
      )}
      <Styled.a key={title} href={href} onClick={showImg}>
        {title}
      </Styled.a>
    </React.Fragment>
  );
};

export default ImgLink;
