import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Me`}</h2>
    <p>{`I am a freelance multi-media artist based in Shanghai with experience in portrait photography, video production and graphic design.`}</p>
    <p>{`I grew up in Sichuan Province in China and studied visual arts in New York and Wisconsin in the US. Having been exposed to diverse cultures and thoughts, I became a visual storyteller who is dedicated to telling stories of how different and how connected we are. I believe that a fleeting moment can be an eternity if we observe it wholeheartedly.`}</p>
    <p>{`Thank you for taking the time to check out my work. For any commissions, collaborative work, or just a “hi” please feel free to contact me.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      